<template>
  <b-carousel :interval="interval" controls img-width="1368" img-height="641">
    <b-carousel-slide v-for="(c, index) in all.child" :key="index">
      <template #img>
        <multi-lang-img
          :id="c._id"
          :all="c"
          :imgPaths="c.props.imgPaths"
          v-bind="c.props"
        />
      </template>
    </b-carousel-slide>
  </b-carousel>
</template>

<script>
import MultiLangImg from "@/views/components/whitelabel-templates/App/components/modules/HomePage/utils/MultiLangImg.vue";
export default {
  name: "AppHomePageCarousel",
  components: {
    MultiLangImg,
  },
  props: ["all", "interval"],
};
</script>
