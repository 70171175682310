<template>
  <component v-bind:is="child.el" :id="child._id" v-bind="child.props" :all="child">
    <template v-if="child.child && child.child.length > 0">
      <template v-for="(item, i) in child.child">
        <item class="" :item="item" :key="i"></item>
      </template>
    </template>
    <template>
        {{ child.content ? child.content:'' }}
    </template>
  </component>
</template>

<script>
import Item from './item.vue'
import MultiLangImg
  from "@/views/components/whitelabel-templates/App/components/modules/HomePage/utils/MultiLangImg.vue";
import AppHomePageCarousel
  from "@/views/components/whitelabel-templates/App/components/common/carrousel/AppHomePageCarrousel.vue";
export default {
  name: "child",
  components:
  {
    Item,
    MultiLangImg,
    AppHomePageCarousel
  },
  props:['child']
}
</script>

<style>
</style>