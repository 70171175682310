<template>
  <multi-media
    :src="mediaSrc"
    :width="imgWidth"
    :height="imgHeight"
    :id="all._id"
  ></multi-media>
</template>

<script>
import MultiMedia from "@/views/components/whitelabel-templates/common/MultiMedia.vue";
import i18n from "@/libs/i18n";

export default {
  name: "MultiLangMedia",
  components: { MultiMedia },
  props: {
    all: {
      type: Object,
      required: true,
    },
  },
  computed: {
    mediaSrc() {
      const { imgPaths } = this.$attrs;
      return imgPaths[i18n.locale];
    },
    imgWidth() {
      if (this.all.size) {
        const dimensions = this.all.size.split(" x ");
        return parseInt(dimensions[0], 10);
      }
      return "100%";
    },
    imgHeight() {
      if (this.all.size) {
        const dimensions = this.all.size.split(" x ");
        return parseInt(dimensions[1], 10);
      }
      return "100%";
    },
  },
};
</script>
